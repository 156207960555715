import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  CardHeader,
  CardTitle,
  Table,
  Container,
  DropdownToggle,
  DropdownMenu, DropdownItem, UncontrolledDropdown
} from "reactstrap";
import MetaTags from "react-meta-tags";

// datatable related plugins
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone
} from "react-bootstrap-table2-paginator";

import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";

//Import Breadcrumb
import Breadcrumbs from "../../components/common/Breadcrumb";
import "../../../src/assets/scss/custom/plugins/_datatables.scss";
import { ApiCore } from "../../helpers/api_helper";
import { Loading } from "../../components/common/Loading";
import { AxiosResponse } from "axios";
import XModal from "../../components/common/XModal";
import CreateForm from "../../components/forms/CreateForm";
import { Endpoints } from "../../utils/Endpoints";
import { StudentFields } from "../../fields/StudentFields";
import SearchMarks from "./search";
import BulkUpload from "./upload";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { can } from "../../utils/Helpers";
import { Link, useHistory } from "react-router-dom";

const Students = () => {

  const [loading, setLoading] = useState<boolean>(true);
  const [syncing, setSyncing] = useState<boolean>(false);
  const [students, setStudents] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState<any>([]);
  const [showStudentDialog, setShowStudentDialog] = useState<boolean>(false);
  const [showStudentBulkDialog, setShowStudentBulkDialog] = useState<boolean>(false);
  const history = useHistory();
  const itemsPerPage = 50;
  const [initialValues, setInitialValues] = useState<any>({
    firstname: "",
    lastname: ""
  });

  const columns = [
    {
      dataField: "studentid",
      text: "Student ID",
    },
    {
      dataField: "name",
      text: "Student Name",
    },
    {
      dataField: "form",
      text: "Class",
      formatter: (cellContent: any, item: any) => <span>{item.form} {item.stream}</span>
    },
    {
      dataField: "gender",
      text: "Gender"
    },
    {
      dataField: "phone",
      text: "Phone"
    },
    {
      dataField: "email",
      text: "Email"
    },
    {
      dataField: "studentcategory.name",
      text: "Category"
    }, {
      dataField: "code",
      text: "Passcode"
    },
    {
      dataField: "house",
      text: "House",
    },
    {
      dataField: "",
      text: "",
      // eslint-disable-next-line react/display-name
      formatter: (cellContent: any, product: any) => <UncontrolledDropdown>
        <DropdownToggle className="card-drop" tag="a">
          <i className="mdi mdi-dots-horizontal font-size-18 text-muted"></i>
        </DropdownToggle>
        {can("manage", "School::Student") ? <>
          <DropdownMenu className="dropdown-menu-end">
            <DropdownItem to="#" onClick={() => handleOption(product, "edit")}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Edit
            </DropdownItem>
            <DropdownItem to="#" onClick={() => handleOption(product, "details")}>
              <i className="mdi mdi-pencil font-size-16 text-success me-1"></i> Details
            </DropdownItem>
          </DropdownMenu>
        </> : <></>}
      </UncontrolledDropdown>
    }
  ];


  const handleOption = (item: any, type: string) => {
    if (type == "edit") {
      setInitialValues(item);
      setShowStudentDialog(true);
    } else if (type == "new") {
      setInitialValues({
        firstname: "",
        lastname: ""
      });
      setShowStudentDialog(true);
    } else if (type == "bulk") {
      setShowStudentBulkDialog(true);
    } else if (type == "details") {
      history.push("/students/" + item.id);
    } else if (type == "sync") {
      if (window.confirm("Do you want to sync this data")) {
        handleSync();
        return;
      }
    }
  }

  const defaultSorted: any = [
    {
      dataField: "name",
      order: "asc"
    }
  ];

  const pageOptions: any = {
    sizePerPage: itemsPerPage,
    totalSize: students.count,
    showTotal: true
  };

  const selectRow: any = {
    mode: "checkbox"
  };

  const request = new ApiCore();

  useEffect(() => {
    searchStudents({})
  }, []);

  const handleUpdate = (item: any) => {
    const items = students.map((i: any) => {
      if (i.id === item.id) {
        return { ...i, ...item };
      }

      return i;
    })
    setStudents(items);
  };

  const searchStudents = (values: any) => {
    values.per_page = 1000;
    request
      .get(Endpoints.students, { params: values }, values.excel && values.excel == 1 ? "arraybuffer" : "json").then((response: AxiosResponse) => {
        setStudents(response.data);
        setTotalRecords(response.data.length);
        setLoading(false);
      }).catch((error) => {
        setLoading(false);
        toast.error(error);
      });
  }

  const handleBulkUpload = (values: any) => {
    const responseType = "arraybuffer";
    toast.promise(request.createFormData(Endpoints.students, values, responseType), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          let message: any = "";
          return message;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    }).then((response: AxiosResponse) => {
      if (values.file) {
        //Nothing to do here
      } else if (values.excel && values.excel == 1) {
        const type = response.headers['content-type']
        const blob = new Blob([response.data], { type: type })
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.download = `mustard-${+ new Date()}.xlsx`
        link.click()
      }
    });
  }

  const handlePromote = () => {
    const responseType = "arraybuffer";
    toast.promise(request.get(Endpoints.promoteStudents, { params: { promote: 1 } }), {
      pending: "Please wait..",
      success: {
        render({ data }) {
          return data;
        }
      },
      error: {
        render({ data }) {
          return data;
        }
      }
    });
  }

  const handleSync = () => {
    setSyncing(true);
    request
      .get(Endpoints.sync).then((response: AxiosResponse) => {
        setSyncing(false);
      }).catch((error) => {
        setSyncing(false);
        toast.error(error);
      });
    }

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Students - Treda</title>
        </MetaTags>

        <Container fluid>
          <Breadcrumbs title="Students" breadcrumbItem="List" />

          <XModal title={"Add a student"}
            width={"lg"}
            open={showStudentDialog}
            toggle={() => setShowStudentDialog(!showStudentDialog)}
            onClose={() => setShowStudentDialog(false)}>

            <CreateForm
              fields={StudentFields}
              initialValues={initialValues}
              submitEndpoint={initialValues.id ? Endpoints.students + "/" + initialValues.id : Endpoints.students}
              submitButtonLabel={initialValues.id ? "Edit Student" : "Add Student"}
              debug={false}
              formData={true}
              handleUpdate={handleUpdate}
              onClose={() => setShowStudentDialog(false)} />

          </XModal>

          <XModal title={"Upload students"}
            width={"lg"}
            open={showStudentBulkDialog}
            toggle={() => setShowStudentBulkDialog(!showStudentBulkDialog)}
            onClose={() => setShowStudentBulkDialog(false)}>

            <BulkUpload handleSubmit={handleBulkUpload} initialValues={{ autoid: 0 }} />

          </XModal>

          <Row>
            <Col md={12} lg={12} xl={12}>
              <Card>
                <CardHeader className="justify-content-between d-flex align-items-center">
                  <CardTitle className="h4">
                    Students
                  </CardTitle>

                  <UncontrolledDropdown className="mt-4 mt-sm-0">
                    <DropdownToggle tag="a" className="btn btn-light">
                      <i className="fa fa-ellipsis-v"></i>
                    </DropdownToggle>

                    <DropdownMenu className="dropdown-menu-end">
                        <DropdownItem to="#" onClick={() => handleOption(null, "sync")}>
                            Synchronize
                        </DropdownItem>
                      {can("manage", "School::Student") && (
                        <>
                          <DropdownItem to="#" onClick={() => handleOption(null, "new")}>
                            Add student
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => handleOption(null, "bulk")}>
                            Upload students
                          </DropdownItem>
                          <DropdownItem to="#" onClick={() => handleOption(null, "promote")}>
                            Promote students
                          </DropdownItem>
                        </>
                      )}

                    </DropdownMenu>
                  </UncontrolledDropdown>
                </CardHeader>
                <CardBody>
                  {syncing && <Loading label="Data synchronization in progress. Please wait.." />}
                  {loading && <Loading label="Loading students. Please wait." />}
                  {!loading &&
                    <PaginationProvider
                      pagination={paginationFactory(pageOptions)}
                    >
                      {({ paginationProps, paginationTableProps }) => (
                        <ToolkitProvider
                          keyField="id"
                          columns={columns}
                          data={students}
                          search
                          bootstrap4
                        >
                          {toolkitProps => (
                            <React.Fragment>
                              <Row className="mb-2">
                                <Col xl="12">
                                  <SearchMarks
                                    handleSearch={(values: any) => {
                                      searchStudents(values);
                                    }}
                                  />
                                </Col>
                                <ToastContainer />
                              </Row>
                              <Row>
                                <Col xl="12">
                                  <div className="table-responsive">
                                    <BootstrapTable
                                      {...toolkitProps.baseProps}
                                      {...paginationTableProps}
                                      defaultSorted={defaultSorted}
                                      classes={
                                        "table align-middle table-nowrap table-hover"
                                      }
                                      bordered={false}
                                      striped={true}
                                    />
                                  </div>
                                </Col>
                              </Row>
                            </React.Fragment>
                          )}
                        </ToolkitProvider>
                      )}
                    </PaginationProvider>
                  }

                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>

      </div>
    </React.Fragment>
  );
};

export default Students;